import * as React from "react";
import {
  WithAsElementProp,
  ClassNameOverrideInterface,
  ClassValue
} from "../@types/component";
import {
  ColoursText,
  ColoursBackground,
  Margin,
  Heights,
  Position
} from "../@types/styling";
import { Icon, IconNames } from "./Icon";
import { FlexRow, Container } from "./Container";
import { CTA } from "./Typography";
import { TooltipLabel } from "./Label";
import { useState } from "react";

const HOVER_EFFECT = "highlight-hover-ef hover-ef";

interface InterfaceButton extends ClassNameOverrideInterface {
  asElement?: React.ElementType;
  icon?: IconNames;
  m?: Margin | Margin[];
  size?: 2 | 3 | 4 | 5;
  pos?: Position;
  basic?: boolean;
  disabled?: boolean;
  primary?: boolean;
  gradient?: boolean;
  secondary?: boolean;
  mainColourOverride?: ColoursText;
  bgColourOverride?: string;
  containerClassNameOverride?: ClassValue;
  tooltip?: React.ReactNode;
  href?: any;
}

export const Button: WithAsElementProp<InterfaceButton> = ({
  icon,
  m = "mr3",
  size = 4, // Currently we have only one size, may change in the future
  pos = "relative",
  asElement = "div",
  disabled,
  children,
  basic,
  primary,
  gradient,
  secondary,
  tooltip,
  containerClassNameOverride, // We need ability to modify the class of the container
  loading,
  mainColourOverride,
  bgColourOverride,
  ...rest
}: any) => {
  const [isHovering, setIsHovering] = useState(false);
  const mainBGColour = bgColourOverride
    ? bgColourOverride
    : primary && !basic
    ? gradient
      ? "bg-brand-gradient"
      : "bg-brand"
    : null;
  let mainColour = mainColourOverride
    ? mainColourOverride
    : primary && !basic
    ? "c-contrast"
    : "c-brand";
  if (secondary) {
    mainColour = "c-secondary";
  }

  rest.classNameOverride = [
    rest.classNameOverride,
    HOVER_EFFECT,
    "overflow-hidden",
    "z-0",
    "before-z-negative", // Fixes label and hover-effect conflicting
    {
      pointer: !disabled
    }
  ];

  const containerClasses = [
    containerClassNameOverride,
    {
      "o-20": disabled
    }
  ];

  return (
    <Container
      layout={{ pos, d: "inline-flex" }}
      dimensions={{ m }}
      flex={{ fai: "items-stretch" }}
      classNameOverride={containerClasses} // Class to handle on hover tooltip
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <FlexRow
        asElement={asElement}
        dimensions={{ h: `h${size}` as Heights, w: "w-100" }}
        flex={{ fjc: "justify-center" }}
        role="button"
        background={mainBGColour as ColoursBackground}
        border={{ br: !children ? "br-100" : "br1" }} // Assuming if no children, we have icon only
        {...rest} // we want the rest properties spread on the button it self, rather than the container with the tooltip
      >
        {icon && (
          <Icon
            size={size}
            name={icon}
            loading={loading}
            colour={mainColour as ColoursText}
          />
        )}
        {children && (
          <FlexRow dimensions={{ m: "ma3" }} flex={{ fai: "items-center" }}>
            <CTA colour={mainColour as ColoursText}>{children}</CTA>
          </FlexRow>
        )}
      </FlexRow>
      {isHovering && tooltip && (
        <TooltipLabel
          className={[
            "slide-in-bottom-tooltip-keyframe-ef",
            "absolute",
            "z-999"
          ]}
        >
          {tooltip}
        </TooltipLabel>
      )}
    </Container>
  );
};
