import * as React from "react";
import ReactToggle, { ToggleProps } from "react-toggle";

export const Toggle: React.FunctionComponent<React.PropsWithChildren<ToggleProps>> = ({
  children,
  ...props
}) => {
  return (
    <label>
      {children}
      <ReactToggle
        className={"highlight-hover-ef hover-ef"}
        icons={false}
        {...props}
      />
    </label>
  );
};
