import * as React from "react";
import classNames from "classnames";
import { WithAsElementProp, Status } from "../@types/component";
import { ColoursText, Margin } from "../@types/styling";
import { Icon, IconNames } from "./Icon";
import { Container } from "./Container";
import * as Typography from "./Typography";
import { getStatusColours } from "./utils";
import { baseColours } from "../themes/colours";

const LabelText: { [key: string]: React.ElementType } = {
  3: Typography.LabelSmall,
  4: Typography.Label,
  5: Typography.Label
};

export interface InterfaceLabel {
  asElement?: React.ElementType;
  icon?: IconNames;
  iconColour?: ColoursText;
  size?: 3 | 4 | 5;
  status?: Status;
  m?: Margin | Margin[];
  basic?: boolean;
  pill?: boolean;
  alert?: boolean;
  reverse?: boolean;
  autoSize?: boolean;
  mainOverrideColour?: string;
}

export const Label: WithAsElementProp<InterfaceLabel> = ({
  icon,
  iconColour,
  m = "mr3",
  status = "default",
  size = 3,
  asElement,
  children,
  basic,
  pill,
  alert,
  reverse,
  mainOverrideColour,
  autoSize,
  ...rest
}: any) => {
  const { mainColour, mainBGColour } = getStatusColours(status, "c-secondary");

  if (rest.onClick) {
    rest.classNameOverride = [
      rest.classNameOverride,
      "highlight-hover-ef hover-ef"
    ];
  }

  const LabelTextComponent = LabelText[size];

  return (
    <Container
      asElement={asElement}
      background={alert && mainBGColour}
      dimensions={{
        pad: basic || !children ? null : ["pv2", reverse ? "pl3" : "pr3"],
        h: autoSize ? "auto" : `h${size}`,
        m
      }}
      layout={{ d: "inline-flex", pos: "relative" }}
      flex={{
        fai: "items-center",
        fd: reverse ? "flex-row-reverse" : "flex-row"
      }}
      border={{
        ...(pill && !basic && { bs: "b--solid", bc: "b--gray-20", bw: "bw1" }),
        br: pill ? "br-pill" : "br1"
      }}
      {...rest}
    >
      <Container
        layout={{ d: "inline-flex" }}
        dimensions={{ m: alert || pill ? "mh2" : "mr2" }}
      >
        {icon && (
          <Icon
            size={(size - 1) as 2 | 3 | 4}
            name={icon}
            colour={iconColour || mainColour}
          />
        )}
      </Container>
      <LabelTextComponent colour={mainOverrideColour || mainColour}>
        {children}
      </LabelTextComponent>
    </Container>
  );
};

export const DataLabel: React.FunctionComponent<React.PropsWithChildren<any>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <span
      className={classNames("bg-default-10 h3 mv2 mr3", className)}
      {...rest}
    >
      <Typography.LabelSmall>{children}</Typography.LabelSmall>
    </span>
  );
};

export const TooltipLabel: React.FunctionComponent<React.PropsWithChildren<any>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <span
      className={classNames("bg-invert pa1 br2 shadow-2", className)}
      {...rest}
    >
      <Typography.LabelSmall colour="c-invert" classNameOverride="nowrap">
        {children}
      </Typography.LabelSmall>
    </span>
  );
};

export const DurationLabel: React.FunctionComponent<React.PropsWithChildren<any>> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <span
      className={classNames("bg-default-10 pa1 br2 shadow-2", className)}
      style={{ backgroundColor: baseColours["black-95"] }}
      {...rest}
    >
      <Typography.LabelSmall colour={"c-contrast"}>
        {children}
      </Typography.LabelSmall>
    </span>
  );
};
