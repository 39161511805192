import classNames from "classnames";
import * as React from "react";
import {
  WithAsElementProp,
  ClassNameOverrideInterface
} from "../@types/component";
import { ColoursText } from "../@types/styling";

import ChevronSVG from "../assets/svg/icons/chevron.svg";
import AllVideoSVG from "../assets/svg/icons/collection.svg";
import BackSVG from "../assets/svg/icons/backward.svg";
import BurgerSVG from "../assets/svg/icons/burger.svg";
import CloseSVG from "../assets/svg/icons/close.svg";
import ConfirmSVG from "../assets/svg/icons/checkmark.svg";
import DetailSVG from "../assets/svg/icons/info.svg";
import DownloadSVG from "../assets/svg/icons/download.svg";
import ExportSVG from "../assets/svg/icons/export.svg";
import ForwardSVG from "../assets/svg/icons/forward.svg";

import PlusSVG from "../assets/svg/icons/plus.svg";
import RefreshSVG from "../assets/svg/icons/generate.svg";
import ScheduleSVG from "../assets/svg/icons/calendar.svg";

import AccentSVG from "../assets/svg/icons/accent.svg";
import AlertSVG from "../assets/svg/icons/warning.svg";
import AlphabeticalSVG from "../assets/svg/icons/alphabetical.svg";
import BellSVG from "../assets/svg/icons/due.svg";
import ChannelSVG from "../assets/svg/icons/channel.svg";
import GradeSVG from "../assets/svg/icons/grade.svg";
import DurationSVG from "../assets/svg/icons/average.svg";
import CheckSVG from "../assets/svg/icons/finalised.svg";
import DaysSVG from "../assets/svg/icons/calendar.svg"; //used twice
import DeleteSVG from "../assets/svg/icons/delete.svg";
import FilterSVG from "../assets/svg/icons/filter.svg";
import FrequencySVG from "../assets/svg/icons/frequency.svg";
import InfoSVG from "../assets/svg/icons/info.svg"; //used twice
import InprogressSVG from "../assets/svg/icons/status.svg"; //in-progress not used anymore
import IntranslationSVG from "../assets/svg/icons/in_translation.svg"; //in-progress not used anymore
import LayoutSVG from "../assets/svg/icons/layout.svg";
import LockSVG from "../assets/svg/icons/lock.svg";
import ManagerSVG from "../assets/svg/icons/manager.svg"; //not used anymore
import MergeSVG from "../assets/svg/icons/merge.svg";
import NextSVG from "../assets/svg/icons/fastforward.svg";
import PauseSVG from "../assets/svg/icons/pause.svg";
import PlaySVG from "../assets/svg/icons/play.svg";
import PreviousSVG from "../assets/svg/icons/rewind.svg";
import PublishedSVG from "../assets/svg/icons/published.svg";
import SpeakerSVG from "../assets/svg/icons/speaker.svg";
import SplitSVG from "../assets/svg/icons/split.svg";
import StatusSVG from "../assets/svg/icons/status.svg"; //this is not used anymore
import TextSVG from "../assets/svg/icons/text.svg";
import TimeSVG from "../assets/svg/icons/time.svg";
import TranslationSVG from "../assets/svg/icons/translation.svg";
import UploadSVG from "../assets/svg/icons/upload.svg";
import UserSVG from "../assets/svg/icons/user.svg";
import VideoSVG from "../assets/svg/icons/video.svg";
import ViewSVG from "../assets/svg/icons/views.svg";
import VolumeSVG from "../assets/svg/icons/sound.svg";
import NavQASVG from "../assets/svg/icons/user.svg"; //used twice
import NavChannelsSVG from "../assets/svg/icons/channel.svg"; //used twice
import NavVideosSVG from "../assets/svg/icons/video.svg"; //used twice
import BalanceSVG from "../assets/svg/icons/balance.svg";
import DiscountSVG from "../assets/svg/icons/discount.svg";
import QuestionSVG from "../assets/svg/icons/question.svg";
import WriteSVG from "../assets/svg/icons/write.svg";

//Unchanged
import LoadingSVG from "../assets/svg/icons/loading.svg";
import EllipsisSVG from "../assets/svg/icons/more.svg";

type SVGComp = React.FunctionComponent<React.PropsWithChildren<React.SVGAttributes<SVGElement>>>;

// Use a class so we can use keys of the class as part of the interface
class IconsMapping {
  public static readonly accent: SVGComp = AccentSVG;
  public static readonly alert: SVGComp = AlertSVG;
  public static readonly alphabetical: SVGComp = AlphabeticalSVG;
  public static readonly allvideo: SVGComp = AllVideoSVG;
  public static readonly back: SVGComp = BackSVG;
  public static readonly balance: SVGComp = BalanceSVG;
  public static readonly burger: SVGComp = BurgerSVG;
  public static readonly bell: SVGComp = BellSVG;
  public static readonly channel: SVGComp = ChannelSVG;
  public static readonly channels: SVGComp = NavChannelsSVG;
  public static readonly check: SVGComp = CheckSVG;
  public static readonly chevron: SVGComp = ChevronSVG;
  public static readonly close: SVGComp = CloseSVG;
  public static readonly confirm: SVGComp = ConfirmSVG;
  public static readonly days: SVGComp = DaysSVG;
  public static readonly delete: SVGComp = DeleteSVG;
  public static readonly detail: SVGComp = DetailSVG;
  public static readonly discount: SVGComp = DiscountSVG;
  public static readonly download: SVGComp = DownloadSVG;
  public static readonly duration: SVGComp = DurationSVG;
  public static readonly ellipsis: SVGComp = EllipsisSVG;
  public static readonly export: SVGComp = ExportSVG;
  public static readonly filter: SVGComp = FilterSVG;
  public static readonly forward: SVGComp = ForwardSVG;
  public static readonly frequency: SVGComp = FrequencySVG;
  public static readonly grade: SVGComp = GradeSVG;
  public static readonly info: SVGComp = InfoSVG;
  public static readonly inprogress: SVGComp = InprogressSVG;
  public static readonly intranslation: SVGComp = IntranslationSVG;
  public static readonly layout: SVGComp = LayoutSVG;
  public static readonly loading: SVGComp = LoadingSVG;
  public static readonly lock: SVGComp = LockSVG;
  public static readonly manager: SVGComp = ManagerSVG;
  public static readonly merge: SVGComp = MergeSVG;
  public static readonly next: SVGComp = NextSVG;
  public static readonly pause: SVGComp = PauseSVG;
  public static readonly play: SVGComp = PlaySVG;
  public static readonly plus: SVGComp = PlusSVG;
  public static readonly previous: SVGComp = PreviousSVG;
  public static readonly published: SVGComp = PublishedSVG;
  public static readonly qa: SVGComp = NavQASVG;
  public static readonly question: SVGComp = QuestionSVG;
  public static readonly refresh: SVGComp = RefreshSVG;
  public static readonly schedule: SVGComp = ScheduleSVG;
  public static readonly speaker: SVGComp = SpeakerSVG;
  public static readonly split: SVGComp = SplitSVG;
  public static readonly status: SVGComp = StatusSVG;
  public static readonly text: SVGComp = TextSVG;
  public static readonly time: SVGComp = TimeSVG;
  public static readonly translation: SVGComp = TranslationSVG;
  public static readonly upload: SVGComp = UploadSVG;
  public static readonly user: SVGComp = UserSVG;
  public static readonly video: SVGComp = VideoSVG;
  public static readonly videos: SVGComp = NavVideosSVG;
  public static readonly view: SVGComp = ViewSVG;
  public static readonly volume: SVGComp = VolumeSVG;
  public static readonly write: SVGComp = WriteSVG;
}

export type IconNames = keyof typeof IconsMapping;

interface InterfaceIcon extends ClassNameOverrideInterface {
  name: IconNames;
  colour?: ColoursText;
  //2 = 20px Small
  //3 = 24px Medium
  //4 = 34px Large
  //5 = 44px X-Large (no examples)
  //always 5px spacing around the icon
  size?: 2 | 3 | 4 | 5 | 6;
  loading?: boolean;
  asElement?: React.ElementType;
  rotate?: 90 | 180 | 270;
}

export const Icon: WithAsElementProp<InterfaceIcon> = ({
  name,
  asElement,
  colour,
  size = 2,
  loading,
  classNameOverride,
  rotate,
  ...rest
}: any) => {
  // @ts-ignore
  const IconSVG: SVGComp = (IconsMapping[name] as SVGComp) || null;
  const dimensions = `w${size} h${size}${rotate ? " rotate-" + rotate : ""}`;
  return (
    <i
      className={classNames(
        "di",
        colour || "c-secondary",
        dimensions,
        loading && "rotate360-keyframe-ef",
        rotate && `rotate-${rotate}`,
        classNameOverride
      )}
      {...rest}
    >
      {IconSVG && <IconSVG className={dimensions} />}
    </i>
  );
};
