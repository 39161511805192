/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { createRef, useEffect, useRef, useState } from "react";
import { Container, FlexRow } from "./Container";
import { H4 } from "./Typography";

interface InterfaceTabs<T> {
  tabs: T[];
  defaultTab?: T;
  children: (tab: T) => React.ReactNode;
}

export const Tabs = <T extends string>({
  tabs,
  children,
  defaultTab
}: InterfaceTabs<T>) => {
  const [selectedTab, setSelectedTab] = useState<T>(defaultTab || tabs[0]);
  const [selectedTabHighlight, setSelectedTabHighlight] = useState<any>();

  // Create an array of references for each tab so we can have correct size highlighter
  const tabRefs = useRef<{
    [key: string]: React.RefObject<HTMLElement>;
  }>(
    tabs.reduce((acc, t) => {
      return {
        ...acc,
        [t]: createRef()
      };
    }, {})
  );

  useEffect(() => {
    setSelectedTabHighlight({
      left: tabRefs.current[selectedTab].current!.offsetLeft,
      width: tabRefs.current[selectedTab].current!.clientWidth
    });
  }, [tabRefs.current[selectedTab]]);

  return (
    <>
      <FlexRow
        layout={{
          pos: "relative"
        }}
        dimensions={{
          pad: ["pv5"],
          m: "mb5",
          w: "w-100"
        }}
        border={{ b: "bb", bc: "b--gray-20" }}
      >
        {tabs.map((t: T, i) => {
          const active = t === selectedTab;
          const isLast = i === tabs.length - 1;

          return (
            <Container
              onClick={() => setSelectedTab(t)}
              dimensions={{ pad: i > 0 ? "ph4" : "pr4" }}
              key={i}
              border={{
                bc: "b--gray-20",
                b: !isLast ? "br" : undefined
              }}
            >
              <H4
                colour={active ? "c-primary" : "c-secondary"}
                ref={tabRefs.current[t]}
                classNameOverride={!active ? "pointer" : undefined}
              >
                {t}
              </H4>
            </Container>
          );
        })}
        {selectedTabHighlight && (
          <Container
            background={"bg-brand"}
            layout={{
              pos: "absolute",
              coor: "bottom-0"
            }}
            classNameOverride={"l-animate-3"}
            style={{
              height: "5px",
              left: `${selectedTabHighlight.left}px`,
              width: `${selectedTabHighlight.width}px`
            }}
          />
        )}
      </FlexRow>
      {children(selectedTab)}
    </>
  );
};
