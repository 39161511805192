import * as React from "react";
import { useState } from "react";
import { useAudio } from "react-use";
import LogoFull from "../../assets/svg/logoFull.svg";
import { H1, H2, H3, H4, Paragraph } from "../../components/Typography";
import { Tabs } from "../../components/Tabs";
import { FlexRow, FlexColumn, Container } from "../../components/Container";
import { Button } from "../../components/Button";
import { Toggle } from "../../components/fields/Toggle";
import Link from "next/link";
import { GA } from "../../analytics/ga";

type Sample = {
  modelName?: string;
  sampleUrl: string;
  alignmentPlot?: string;
  comment?: string;
  text?: string;
};

export const AudioPlayer: React.FunctionComponent<{
  src: string;
}> = ({ src }) => {
  const [audio, state, controls] = useAudio({
    src,
    autoPlay: false,
    preload: "auto"
  });

  return (
    <>
      <Button
        m={"ma0"}
        icon={state.paused ? "play" : "pause"}
        onClick={() => {
          if (state.paused) {
            GA.event({
              action: "play",
              label: src,
              category: "audio"
            });
            controls.seek(0);
            controls.play();
          } else {
            controls.pause();
          }
        }}
        primary
      />
      {audio}
    </>
  );
};

export const Samples: React.FunctionComponent<{
  headers: string[];
  samples: Sample[];
}> = ({ headers, samples }) => {
  return (
    <table className="mt4">
      <thead>
        <tr>
          <th></th>
          {headers.map(s => (
            <th
              className={
                s === "Comments" || s === "Model Name" || s === "Text"
                  ? "b tl pl4"
                  : "b"
              }
              key={s}
            >
              <H4>{s}</H4>
            </th>
          ))}
        </tr>
      </thead>
      {samples.map(
        ({ modelName, sampleUrl, text, alignmentPlot, comment }, i) => (
          <tr className="tc" key={sampleUrl}>
            <td className="pa2 v-mid">
              <Paragraph classNameOverride="b">{i + 1}</Paragraph>
            </td>
            {modelName ? (
              <td className="pv2 pl4 pr2 v-mid tl">
                <Paragraph classNameOverride="b">{modelName}</Paragraph>
              </td>
            ) : null}
            <td className="pa2 w-6 v-mid">
              <AudioPlayer src={sampleUrl} />
            </td>

            {alignmentPlot ? (
              <td>
                <img src={alignmentPlot} />{" "}
              </td>
            ) : null}
            {text ? (
              <td className="pv2 pl4 pr2 v-mid tl nowrap-ns">
                <Paragraph>{text}</Paragraph>
              </td>
            ) : null}
            {comment ? (
              <td className="pv2 pl4 pr2 v-mid tl">
                <Paragraph>{comment}</Paragraph>
              </td>
            ) : null}
          </tr>
        )
      )}
    </table>
  );
};

export const ManySamples: React.FunctionComponent<{
  title?: string;
  samples: {
    Sentence: string;
    "Unseen phonemes": string;
    AUTO: string;
    MAPPED: string;
    RANDOM: string;
  }[];
}> = ({ title, samples }) => {
  const headers = Object.keys(samples[0]);
  return (
    <table className="mt4 center">
      <thead>
        {title && (
          <tr>
            <th className={"pa2 b tl bb b--gray-20"}>
              <H4 dangerouslySetInnerHTML={{ __html: title }}></H4>
            </th>
          </tr>
        )}
        <tr>
          {headers.map(s => (
            <th className={s === "Sentence" ? "pl2 pr3 b tl" : "ph3 b"} key={s}>
              <H4>{s}</H4>
            </th>
          ))}
        </tr>
      </thead>
      {samples.map(
        ({
          Sentence,
          "Unseen phonemes": unseenPhones,
          AUTO: PHON,
          MAPPED,
          RANDOM
        }) => (
          <tr className="tc" key={Sentence}>
            <div className="dtc pa2 v-mid">
              <Paragraph
                classNameOverride="b tl"
                dangerouslySetInnerHTML={{ __html: Sentence }}
              ></Paragraph>
            </div>
            <div className="dtc pv2 pl4 pr2 v-mid">
              <Paragraph
                dangerouslySetInnerHTML={{ __html: unseenPhones }}
              ></Paragraph>
            </div>
            <div className="dtc pa2 w-6 v-mid">
              <AudioPlayer src={PHON} />
            </div>
            <div className="dtc pa2 w-6 v-mid">
              <AudioPlayer src={MAPPED} />
            </div>
            <div className="dtc pa2 w-6 v-mid">
              <AudioPlayer src={RANDOM} />
            </div>
          </tr>
        )
      )}
    </table>
  );
};

export const ManySamplesTCESS: React.FunctionComponent<{
  title?: string;
  samples: {
    "Shifted Feature": string;
    [key: string]: string;
  }[];
}> = ({ title, samples }) => {
  const headers = Object.keys(samples[0]);
  return (
    <section className="mt4 ph7">
      {title && (
        <div className={"pa2 mb3 b bb b--gray-20"}>
          <H4 dangerouslySetInnerHTML={{ __html: title }}></H4>
        </div>
      )}
      <header>
        <div className="flex">
          {headers.map(s => (
            <div
              className={s === "Shifted Feature" ? "pl3" : "ph2 b tc"}
              key={s}
              style={{
                flex: 1,
                ...(s === "Shifted Feature" && { flexBasis: "50px" })
              }}
            >
              <H4>{s}</H4>
            </div>
          ))}
        </div>
      </header>
      {samples.map(
        ({ "Shifted Feature": shiftedFeatures, ...otherVariants }) => (
          <div className="flex" key={shiftedFeatures}>
            <div className="w-50" style={{ flex: 1, flexBasis: "50px" }}>
              <Paragraph
                classNameOverride="b tl pl4"
                dangerouslySetInnerHTML={{ __html: shiftedFeatures }}
              ></Paragraph>
            </div>
            {Object.keys(otherVariants).map(k => (
              <div
                className="pa2 tc"
                key={otherVariants[k]}
                style={{ flex: 1 }}
              >
                <AudioPlayer src={otherVariants[k]} />
              </div>
            ))}
          </div>
        )
      )}
    </section>
  );
};

export const ManySamplesTemporalControl: React.FunctionComponent<{
  title?: string;
  samples: {
    [key: string]: string;
  }[];
}> = ({ title, samples }) => {
  const headers = Object.keys(samples[0]);
  return (
    <table className="mt4 center mh5">
      <thead>
        {title && (
          <tr>
            <th className={"pa2 b tl bb b--gray-20"} style={{ width: "150px" }}>
              <H4 dangerouslySetInnerHTML={{ __html: title }}></H4>
            </th>
          </tr>
        )}
        <tr>
          {headers.map(s => (
            <th
              className={s === "Shifted Feature" ? "pt3 w7 b tl" : "ph2 b"}
              key={s}
            >
              <H4 dangerouslySetInnerHTML={{ __html: samples[0][s] }}></H4>
            </th>
          ))}
        </tr>
      </thead>
      {samples.slice(1).map(({ Speaker, ...otherVariants }, i) => (
        <tr className="tc" key={i}>
          <div className="dtc pv2 pl4 v-mid w7">
            <Paragraph
              classNameOverride="b tl"
              dangerouslySetInnerHTML={{ __html: Speaker }}
            ></Paragraph>
          </div>
          {Object.keys(otherVariants).map(k => (
            <div className="dtc ph2  v-mid" key={otherVariants[k]}>
              <AudioPlayer src={otherVariants[k]} />
            </div>
          ))}
        </tr>
      ))}
    </table>
  );
};

export const ProsodySamples: React.FunctionComponent<{
  title?: string;
  rows: any;
}> = ({ title, rows }) => {
  const [isOracle, setIsOracle] = useState(false);

  return (
    <section className="mt4 ph7">
      {title && (
        <div className={"pa2 mb3 b bb b--gray-20"}>
          <H4 dangerouslySetInnerHTML={{ __html: title }}></H4>
        </div>
      )}
      <header>
        <div className="flex mb3">
          <div
            className={"ph2 b tr"}
            style={{
              flex: 1
            }}
          >
            <H4 classNameOverride={"mr3"}>Speaker</H4>
          </div>
          <div
            className={"ph2 b tc "}
            style={{
              flex: 1
            }}
          >
            <H4>RNN</H4>
          </div>
          <div
            className={"ph2 b tc"}
            style={{
              flex: 1
            }}
          >
            <H4>CONV</H4>
          </div>
        </div>
      </header>
      {rows.map((row: any, i: number) => (
        <div className="flex" key={i}>
          <div className="b bb b--gray-20" style={{ flex: 1 }}>
            <Paragraph classNameOverride="b tr pr4 ">{row.row}</Paragraph>
          </div>
          {row.samples.map((s: any) => (
            <div
              className="pa2 tc b bb b--gray-20"
              key={s}
              style={{
                flex: 1,
                background: isOracle && s.oracle && "rgba(252, 78, 54, 0.5)"
              }}
            >
              <AudioPlayer src={s["sampleUrl"]} />
            </div>
          ))}
        </div>
      ))}
      <div
        className="pa2 tc flex"
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <Paragraph classNameOverride="b tl pa4 ">Show ORACLE choice</Paragraph>
        <Toggle onClick={() => setIsOracle(o => !o)} />
      </div>
    </section>
  );
};

export const Section: React.FunctionComponent<React.PropsWithChildren<{ title: string }>> = ({
  title,
  children
}) => {
  return (
    <Container
      border={{
        b: "bt",
        bc: "b--brand"
      }}
      dimensions={{
        pad: "pv5"
      }}
    >
      <H3>{title}</H3>
      <Paragraph>
        <span className="b">Description:</span>
      </Paragraph>
      {children}
    </Container>
  );
};

export const TabWithSamples: React.FunctionComponent<{
  samples: {
    [key: string]: {
      sentence: string;
      samples: Sample[];
    };
  };
}> = ({ samples }) => {
  const tabs = Object.keys(samples);
  const [currentTab, setCurrentTab] = useState<string>(tabs[0]);
  return (
    <Tabs tabs={tabs} defaultTab={currentTab}>
      {(tab: string) => {
        setCurrentTab(tab);
        return (
          <FlexColumn dimensions={{ w: "w-100" }}>
            <Paragraph>
              <span className="b"> Sentence text: </span>
              {samples[currentTab].sentence}
            </Paragraph>
            <Samples
              headers={["Model Name", "Sample", "Alignment Plot"]}
              samples={samples[currentTab].samples}
            />
          </FlexColumn>
        );
      }}
    </Tabs>
  );
};

export const Footer = () => (
  <FlexColumn
    dimensions={{ pad: "pv4", m: "mt5" }}
    border={{ b: "bt", bc: "b--gray-20" }}
  >
    <FlexRow>
      <a href="https://www.papercup.com">
        <LogoFull />
      </a>
      <a href={"/"} className={"ml-auto b"}>
        <Paragraph m="mr5">Our Research</Paragraph>
      </a>
      <a href={"https://papercup.dev/"} className={" b"}>
        <Paragraph m="mr5">Our Blog</Paragraph>
      </a>
    </FlexRow>
    <Paragraph m="mt4">© 2023 Papercup Technologies Ltd.</Paragraph>
  </FlexColumn>
);

export const PaperSummary: React.FunctionComponent<React.PropsWithChildren<{
  samplesLink: string;
  title: string;
  authors: string;
  paperLink?: string;
}>> = ({ samplesLink, title, authors, children, paperLink }) => (
  <div className={samplesLink ? "mt6" : ""}>
    {samplesLink ? <H2>{title}</H2> : <H1>{title}</H1>}
    <Paragraph>
      <span className="b">Authors: {authors}</span>
    </Paragraph>
    <Paragraph>
      <span className="b">Abstract: </span>
      {children}
    </Paragraph>
    {paperLink ? (
      <a href={paperLink} target="_blank" rel="noopener noreferrer">
        <Button size={2} primary gradient>
          Paper
        </Button>
      </a>
    ) : null}
    {samplesLink ? (
      <Link href={samplesLink}>
          <Button size={2} primary gradient>
            Samples
          </Button>
      </Link>
    ) : null}
  </div>
);
