import classNames from "classnames";
import * as React from "react";
import {
  ClassNameOverrideInterface,
  WithAsElementProp,
  WithAsElementPropWithRef
} from "../@types/component";
import {
  BorderProperties,
  ColoursBackground,
  DimensionProperties,
  FlexProperties,
  LayoutProperties
} from "../@types/styling";

export interface InterfaceContainer extends ClassNameOverrideInterface {
  asElement?: React.ElementType;
  border?: BorderProperties;
  dimensions?: DimensionProperties;
  flex?: FlexProperties;
  layout?: LayoutProperties;
  background?: ColoursBackground;
}

export const Container: WithAsElementPropWithRef<InterfaceContainer> = React.forwardRef(
  (
    {
      asElement,
      border,
      dimensions,
      flex,
      layout,
      background,
      children,
      classNameOverride,
      ...rest
    }: any,
    ref: any
  ) => {
    const Component = asElement || "div";

    return (
      <Component
        ref={ref}
        className={classNames(
          border && Object.values(border),
          dimensions && Object.values(dimensions),
          flex && Object.values(flex),
          layout && Object.values(layout),
          background,
          classNameOverride
        )}
        {...rest}
      >
        {children}
      </Component>
    );
  }
) as WithAsElementPropWithRef<InterfaceContainer>;

export const FlexColumn: WithAsElementProp<InterfaceContainer> = ({
  children,
  flex,
  layout,
  ...props
}: any) => {
  return (
    <Container
      layout={Object.assign({ d: "flex" }, layout)}
      flex={Object.assign({ fd: "flex-column" }, flex)}
      {...props}
    >
      {children}
    </Container>
  );
};

export const FlexRow: WithAsElementProp<InterfaceContainer> = ({
  children,
  flex,
  layout,
  ...props
}: any) => {
  return (
    <Container
      layout={Object.assign({ d: "flex" }, layout)}
      flex={Object.assign({ fd: "flex-row" }, flex)}
      {...props}
    >
      {children}
    </Container>
  );
};
